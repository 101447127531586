import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { Gift, Whatsapp } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useContext } from 'react'

import { GeniusButton } from './GeniusButton'
import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'
import { Toolbars } from './Toolbars'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  pageID,
}: Props) {
  const {
    languageCode,
    languagePrefix,
    logo,
    logoSticky,
    shopURL,
    siteName,
    whatsappURL,
    variant,
  } = useContext(HeaderContext) as any
  const [_locked, setLocked] = useLockedBody()

  const languages = languagesList.filter((t) => t.pageID === pageID)

  const activeLanguage = useRef(null)
  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)
  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 200)
    }

    window.addEventListener('scroll', onScroll)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      window.removeEventListener('scroll', onScroll)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={4}
          row
          variant={variant}
        >
          {variant !== 'concierge' ? (
            <Hamburger
              dial={4}
              onClick={() => {
                if (!navigationStatus) {
                  setNavigationStatus(true)
                  setLocked(true)
                } else {
                  setNavigationStatus(false)
                }
              }}
              row
            >
              <HamburgerLines className="hamburger-lines">
                <HamburgerLine />
                <HamburgerLine />
                <HamburgerLine />
              </HamburgerLines>
              <HambugerLabel>
                {useVocabularyData('menu', languageCode)}
              </HambugerLabel>
            </Hamburger>
          ) : null}

          <Languages ref={activeLanguage} variant={variant}>
            <LanguageActive
              className={othersLanguagesStatus ? 'open' : undefined}
            >
              {languages
                .filter((t) => t.languagePrefix === languagePrefix)
                .map((item, index) => (
                  <LanguageItem className="active" key={index} {...item} />
                ))}
            </LanguageActive>
            <OthersLanguages
              className={othersLanguagesStatus ? 'open' : undefined}
            >
              {languagesList
                .filter((t) => t.languagePrefix !== languagePrefix)
                .map((item, index) => (
                  <LanguageItem key={index} {...item} />
                ))}
            </OthersLanguages>
          </Languages>

          <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
            {logoSticky ? (
              <Media lessThan="desktopSmall">
                <Logo
                  className="logo"
                  src={logoSticky}
                  alt={siteName}
                  width="131"
                  height="34"
                  variant={variant}
                />
              </Media>
            ) : null}
            {logo ? (
              <Media greaterThanOrEqual="desktopSmall">
                <Logo
                  className="logo"
                  src={scroll || variant === 'compact' ? logoSticky : logo}
                  alt={siteName}
                  width="87"
                  height="171"
                  variant={variant}
                />
              </Media>
            ) : null}
          </Link>

          {shopURL && variant !== 'concierge' ? (
            <Media greaterThanOrEqual="desktopSmall">
              <Shop
                aria-label="Shop"
                href={`${shopURL}&lang=${languagePrefix || 'en'}`}
                rel="noreferrer"
                target="_blank"
              >
                <Gift />
              </Shop>
            </Media>
          ) : null}

          {whatsappURL && variant !== 'concierge' ? (
            <WhatsappWrap
              aria-label="Whatsapp"
              href={whatsappURL}
              rel="noreferrer"
              target="_blank"
            >
              <Whatsapp />
            </WhatsappWrap>
          ) : null}

          {variant !== 'concierge' ? (
            <Media greaterThanOrEqual="desktopSmall">
              <CTA
                label={useVocabularyData('book-now', languageCode)}
                onClick={() => {
                  if (!modalIBEStatus) {
                    setModalIBEStatus(true)
                    setLocked(true)
                  } else {
                    setModalIBEStatus(false)
                    setLocked(false)
                  }
                }}
              />
            </Media>
          ) : null}
        </Head>

        {variant !== 'nobutton' && variant !== 'concierge' ? (
          <Media greaterThanOrEqual="desktopSmall">
            <GeniusButton />
          </Media>
        ) : null}

        <Modal className={navigationStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1199) {
                setLocked(false)
              } else {
                document.body.style.overflow = 'auto'
              }
              setNavigationStatus(false)
            }}
          />
          <Navigation
            menuItems={menuItems}
            modalStatus={navigationStatus}
            navigationStatus={navigationStatus}
          />
        </Modal>

        <Media greaterThanOrEqual="desktopSmall">
          <Modal className={modalIBEStatus ? ' open' : undefined}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                setLocked(false)
                setModalIBEStatus(false)
              }}
              variant="R"
            />
            <ModalIBE modalStatus={modalIBEStatus} />
          </Modal>
        </Media>

        {variant !== 'concierge' ? (
          <Media lessThan="desktopSmall">
            <Toolbars />
          </Media>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  .logo {
    width: auto;
    height: 2.125rem;
    top: 0.8125rem;
  }

  @media (max-width: 374px) {
    .logo {
      height: 1.5625rem;
      top: 1.0625rem;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}
  }

  @media (max-width: 1199px) {
    animation: none !important;

    ${HeaderFixedStyle}
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  padding: 1.25rem 3.125rem 1.25rem 2.6875rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(2) {
          width: 50%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    order: 3;
    padding: 1.375rem 0.875rem 1.3125rem;
  }
`

const HamburgerLines = styled.div`
  width: 2rem;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.1875rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    margin: 0.25rem 0;
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    &:nth-of-type(2) {
      width: 75%;
    }
  }
`

const HambugerLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-left: 1rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Languages = styled.div<HeadProps>`
  margin-right: auto;
  position: relative;
  z-index: 3;

  ${({ variant }) => {
    switch (variant) {
      case 'concierge':
        return css`
          margin-right: 0;
          margin-left: auto;
          > div {
            background: ${theme.colors.variants.neutralDark2};

            @media (max-width: 1199px) {
              svg {
                fill: ${theme.colors.variants.neutralLight4};
              }
            }
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    margin-right: 0;
    margin-left: auto;
  }
`

const LanguageActive = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`

const OthersLanguages = styled.div`
  opacity: 0;
  position: absolute;
  top: 3.75rem;
  left: 0;
  transform: translateY(-3.75rem);
  transition: 0.25s ease-in-out;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
  }

  a {
    border-top: 1px solid ${rgba(theme.colors.variants.neutralDark2, 0.15)};
    &:first-of-type {
      border-top: 0;
    }
  }
`

const Logo = styled.img<HeadProps>`
  width: auto;
  height: 10.6875rem;
  margin: auto;
  position: absolute;
  top: 1.875rem;
  left: 50%;
  transform: translateX(-50%);

  ${({ variant }) => {
    switch (variant) {
      case 'concierge':
        return css`
          @media (max-width: 1199px) {
            left: 50% !important;
            transform: translateX(-50%) !important;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    left: 1.5625rem;
    transform: none;
  }
`

const Shop = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`

const WhatsappWrap = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`

const CTA = styled(Button)`
  margin-top: 0;
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'nobutton' | 'concierge'
